<template>
  <div class="about">
    Logs
    <p>Token : {{getToken}} <a href="#" @click.prevent="logout" class="btn btn-hero-info">Reset token</a></p>

    <a href="#" @click.prevent="resetforms()" class="btn btn-hero-info">Reset forms</a>
    <a href="#" @click.prevent="refreshevents()" class="btn btn-hero-info">Reset events</a>
    <a href="#" @click.prevent="resetinter()" class="btn btn-hero-info">Reset inter</a>
    
    <p>Nb inter à synchro : {{tosyncsInter.length}}</p>
    
    <p>Nb photo : </p>
    <ul>
      <li v-for="nameofpicutues in getAll()">
        <ul>
          <li v-for="picture in nameofpicutues">
              {{picture}} <a href="#"  class="btn btn-hero-danger" @click.prevent="deletepicture(picture)">X</a>
          </li>
        </ul>
      </li>
    </ul>
    <br>
    <p>Inter : </p>
    <ul>
      <li v-for="inter in getAllInter">
          {{inter}} <a href="#" class="btn btn-hero-danger" @click.prevent="deleteInter(inter.uid)">X</a>
      </li>
    </ul>
    
    <a href="#" @click.prevent="pushAllIntervention()" class="btn btn-hero-success">Push inter</a>
    <a href="#" @click.prevent="pushAllPictures()" class="btn btn-hero-success">Push pictures</a>
    <br>
    <a href="#" @click.prevent="refreshconfig()" class="btn btn-hero-success">Refresh Config</a>

    <br>
    <br>
    <textarea cols="50" rows="5">{{getRegisterToken}}</textarea>
    <a href="#" @click.prevent="resetRegisterToken()" class="btn btn-hero-success">Reset Token Notitfication</a>


  </div>
</template>


<script>

  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'logs',
    methods: {
      ...mapActions('forms', {'resetforms': 'reset'}),
      ...mapActions('events', {'refreshevents': 'refresh'}),
      ...mapActions('interventions', {'resetinter': 'reset', 'pushAllIntervention': 'pushAll', 'deleteInter': 'delete'}),
      ...mapActions('pictures', {'pushAllPictures': 'sync', 'deletepicture': 'delete'}),
      ...mapActions('login', ['logout']),
      ...mapActions('config', { 'refreshconfig': 'refresh', 'resetRegisterToken':'resetRegisterToken'}),
    },
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters('login',[
        'getToken',
      ]),
      ...mapGetters('interventions',{'getAllInter': 'getAll', 'tosyncsInter': 'tosyncs'}),
      ...mapGetters('pictures',[
        'getAll',
      ]),
      ...mapGetters('config',['getRegisterToken'])
    },
    created(){
      this.$store.dispatch('pictures/loadall');

    },
  }
</script>
